<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '服务展示',
          list: [
            {
              name: '服务分类',
              path: '/mall/service/category'
            },
            {
              name: '服务列表',
              path: '/mall/service/service'
            }
          ]
        }
      ]
    }
  }
}
</script>
